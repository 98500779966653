
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { ICredentialSet } from "@/interfaces/api/v2/credential-set.interface";
import { ICredentialProof } from "@/interfaces/api/v3/credential-verified.interface";
import { IFormattedTopic } from "@/interfaces/api/v2/topic.interface";
import { ICredentialFormatted } from "@/interfaces/api/v4/credential.interface";
import i18n from "@/i18n/index";
import router from "@/router";
import moment from "moment";
import BackTo from "@/components/shared/BackTo.vue";
import { unwrapTranslations, isExpired } from "@/utils/entity";
import { ICredentialType } from "@/interfaces/api/v2/credential-type.interface";

interface Data {
  headers: Record<string, string>[];
}

@Component({
  components: {
    BackTo,
  },
  computed: {
    ...mapGetters([
      "mdiArrowLeft",
      "mdiShieldCheckOutline",
      "mdiCheckBold",
      "selectedTopic",
      "selectedTopicFullCredentialSet",
      "getSelectedCredential",
      "getPresentationId",
      "getPresentationEX",
      "loading",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchSelectedCredential",
      "setLoading",
      "fetchPresId",
      "fetchPresEx",
      "fetchFormattedIdentifiedTopic",
    ]),
  },
})
export default class CredentialDetail extends Vue {
  selectedTopic!: IFormattedTopic;
  selectedTopicFullCredentialSet!: Array<ICredentialSet>;
  getSelectedCredential!: ICredentialFormatted | undefined;
  loading!: boolean;
  getPresentationId!: string;
  getPresentationEX!: ICredentialProof;
  sourceId!: string;
  isExpired = isExpired;
  fetchSelectedCredential!: (id: string) => Promise<void>;
  fetchPresId!: (id: string) => Promise<void>;
  fetchPresEx!: (params: { id: string; presId: string }) => Promise<void>;
  fetchFormattedIdentifiedTopic!: ({
    sourceId,
    type,
  }: {
    sourceId: string;
    type: string;
  }) => Promise<void>;
  setLoading!: (loading: boolean) => void;

  data(): Data {
    return {
      headers: [
        { text: "attr_name", align: "start", value: "attr_name" },
        { text: "attr_val", align: "start", value: "attr_val" },
      ],
    };
  }

  get selectedCredential(): ICredentialFormatted | undefined {
    return this.getSelectedCredential;
  }

  get now(): string {
    return moment(new Date()).format("MMM, DD YYYY [at] hh:mm a");
  }

  get topicSourceId(): string | undefined {
    return this.sourceId;
  }

  get topicName(): string | undefined {
    return this.selectedTopic.names[0]?.text;
  }

  get issuer(): string | undefined {
    return this.selectedCredential?.credential_type?.issuer?.name;
  }

  get credentialType(): ICredentialType | undefined {
    return this.selectedCredential?.credential_type;
  }

  get credentialTypeDescription(): string | undefined {
    if (!this.selectedCredential) {
      return undefined;
    }
    if (this.credentialType?.format === "vc_di") {
      // TODO: Eventually, this should be a translation from OCA
      return this.credentialType?.schema?.name;
    }
    return (
      unwrapTranslations(this.credentialType?.schema_label)?.[i18n.locale]
        ?.label ??
      this.credentialType?.description ??
      ""
    );
  }
  get revoked(): boolean | undefined {
    return (
      this.selectedCredential?.revoked ||
      !!this.isExpired(this.selectedCredential?.attributes)
    );
  }

  get effectiveDate(): Date | undefined {
    return this.selectedCredential?.effective_date;
  }

  get issuedDate(): Date | undefined {
    return this.selectedCredential?.create_timestamp;
  }

  get revokedDate(): Date | string | undefined {
    return (
      this.selectedCredential?.revoked_date ||
      this.isExpired(this.selectedCredential?.attributes)
    );
  }

  get errorMessage(): string {
    if (!this.revoked) {
      return "";
    }
    // Credential is expired and has been replaced. It can no longer be verified. (replaced and expired)
    // Credential is expired. It can no longer be verified. (expired)
    // Credential has been replaced. It can no longer be verified. (replaced)
    const replaced = !!this.selectedCredential?.revoked;
    const expired = !!this.isExpired(this.selectedCredential?.attributes);
    let base = "Credential ";
    if (expired) {
      base += "is expired";
      if (replaced) {
        base += " and ";
      } else {
        base += ".";
      }
    }
    if (replaced) {
      base += "has been replaced.";
    }
    return base + " It can no longer be verified";
  }

  get proofRaw(): string | undefined {
    const rawVals = this.getPresentationEX;
    if (rawVals === undefined) {
      return rawVals;
    }
    return JSON.stringify(rawVals?.result, null, 2);
  }

  get proofValues(): Record<string, string>[] | undefined {
    const rawVals =
      this.getPresentationEX?.result?.presentation?.requested_proof
        ?.revealed_attr_groups?.["self-verify-proof"]?.values;
    if (rawVals === undefined) {
      return rawVals;
    }
    return Object.keys(rawVals).map((key) => {
      return { attr_name: key, attr_val: rawVals[key].raw };
    });
  }

  get rawData(): unknown {
    if (this.credentialType?.format === "vc_di") {
      return JSON.stringify(this.selectedCredential?.raw_data || {}, null, 2);
    }
    return null;
  }

  // FIXME: Need to fix timing issue in the API first
  async created(): Promise<void> {
    this.setLoading(true);
    const { sourceId, type, credentialId } = this.$route.params;
    this.sourceId = sourceId;
    if (sourceId && credentialId) {
      await Promise.all([
        this.fetchSelectedCredential(credentialId),
        this.fetchPresId(credentialId),
        this.fetchFormattedIdentifiedTopic({
          sourceId,
          type,
        }),
      ]);

      // DEPRECATED: need a small timeout because the credential isn't always verified after fetchPresId returns
      await new Promise((r) => setTimeout(r, 1000));

      if (this.getPresentationId) {
        await this.fetchPresEx({
          id: credentialId,
          presId: this.getPresentationId,
        });
      }
    } else {
      router.push("/search");
    }
    if (!this.selectedCredential || !this.selectedTopic) {
      // cred or source id invalid, failed to load credential
      console.error(
        "Invalid credential or source Id. Credential detail not retrieved"
      );
      router.push("/search");
    }
    this.setLoading(false);
  }
}
