
import { Component, Vue } from "vue-property-decorator";
import CredentialDetail from "@/components/entity/credentialDetail/CredentialDetail.vue";

@Component({
  components: {
    CredentialDetail,
  },
})
export default class Credential extends Vue {}
